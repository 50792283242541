<script setup lang="ts">
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import Icon from "@/src/components/Icon.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextBody4 from "@/src/components/TextBody4.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

const { n } = useI18n();

defineProps<{
  image: string;
  serviceName: string;
  textBody: string;
  priceCents: number;
  durationMins: number;
}>();

defineEmits<{
  back: [];
}>();
</script>

<template>
  <div class="bg-button-disabled p-5">
    <ButtonPlaintext class="flex justify-start items-center cursor-default ">
      <TextHeader2 @click="$emit('back')" class="cursor-pointer flex items-center gap-x-2"><Icon :icon="faAngleLeft" size="sm"></Icon>Back</TextHeader2>
    </ButtonPlaintext>
  </div>
  <div class="p-5">
    <h3 class="flex justify-between mt-5">
      <TextHeader2>{{ serviceName }}</TextHeader2>
    </h3>
    <div class="space-x-5 mb-10">
      <TextBody3>{{ durationMins }} Mins</TextBody3>
      <TextBody3>{{ n(priceCents/100, 'currency') }}</TextBody3>
    </div>
    <div>
      <TextBody4>
        {{ textBody }}
      </TextBody4>
    </div>
    <div>
      <img :src="image" alt="Service image" class="w-[500px] aspect-square mt-10"/>
    </div>
  </div>
</template>
