import type { Nullable } from "@/src/lib/nullable";
import type {
  Email,
  Password,
  User,
  ZenotiGuestId,
  ZenotiVerificationCode,
  ZenotiVerificationId,
} from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import _ from "lodash";
import { defineStore } from "pinia";

export type UserState = {
  user: Nullable<Omit<User, "id">>;
  passwordReset: {
    verificationId: ZenotiVerificationId | null;
    guestId: ZenotiGuestId | null;
    email: Email | null;
  };
};

export class UserStoreInvalidUserAuthError extends Error {}

export const useUserStore = defineStore("user", {
  state: (): UserState => {
    return {
      user: {
        email: null,
        firstName: null,
        lastName: null,
        marketingEmailOptIn: null,
        marketingSmsOptIn: null,
        dateOfBirthUtc: null,
        phoneNumber: null,
      },
      passwordReset: {
        verificationId: null,
        guestId: null,
        email: null,
      },
    };
  },
  actions: {
    async fetchUser(): Promise<void> {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new UserStoreInvalidUserAuthError("Fetching user details requires the user to be authenticated");
      }

      const userDetails = await authStore.townhouseApiClient.userGet(authStore.userId);

      this.$patch({
        user: _.omit(userDetails, ["id"]),
      });
    },
    async updateUser(details: Partial<UserState["user"]>): Promise<void> {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new UserStoreInvalidUserAuthError("Updating user details requires the user to be authenticated");
      }

      await authStore.townhouseApiClient.userUpdate(authStore.userId, {
        firstName: details.firstName || undefined,
        lastName: details.lastName || undefined,
        dateOfBirthDateUtc: details.dateOfBirthUtc || undefined,
        phone: details.phoneNumber || undefined,
        marketingEmailOptIn: details.marketingEmailOptIn ?? undefined,
        marketingSmsOptIn: details.marketingSmsOptIn ?? undefined,
      });

      this.$patch({
        user: details,
      });
    },
    clearPasswordResetData() {
      this.$patch({
        passwordReset: {
          verificationId: null,
          email: null,
          guestId: null,
        },
      });
    },
    async resetPassword(email: Email): Promise<void> {
      this.clearPasswordResetData();
      const authStore = useAuthStore();

      const { verificationId, guestId } = await authStore.townhouseApiClient.userResetPassword(email);

      this.$patch({
        passwordReset: {
          verificationId,
          guestId,
          email,
        },
      });
    },
    async completeResetPassword(verificationCode: ZenotiVerificationCode, newPassword: Password): Promise<void> {
      const authStore = useAuthStore();
      await authStore.townhouseApiClient.userCompleteResetPassword(
        this.passwordReset.guestId as ZenotiGuestId,
        this.passwordReset.verificationId as ZenotiVerificationId,
        verificationCode,
        newPassword,
      );

      this.clearPasswordResetData();
    },
  },
});
