export const localEnGb = {
  account: {
    header: "Your Account",
    details: {
      label: "Account",
      header: "Account Details",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      mobile: "Mobile",
      dateOfBirth: "Date of Birth",
      phone: "Mobile",
      referral: "Referral Code",
      password: "Password",
      marketing: {
        description: "Yes please – sign me up to exclusive offers, birthday treats and collaboration freebies!",
        email: "Email",
        sms: "SMS",
      },
      update: "Update",
      success: "Your account has been updated.",
      error: "Failed to update your account details.",
    },
    appointments: {
      label: "Appointments",
    },
    rewards: {
      label: "Townhouse Rewards",
    },
    giftCards: {
      label: "Gift Cards",
    },
    paymentAccounts: {
      label: "Payment Details",
      card: "Card ending in {cardLast4}",
      delete: "Delete",
      confirmDelete: "Are you sure you want to remove the payment card ending in {cardLast4}?",
      deleteFailed: "Failed to delete your payment card. Please try again later.",
    },
    logOut: {
      label: "Log Out",
    },
  },
  bookingSummary: {
    guests: {
      one: "Just Me!",
    },
    headers: {
      maniPedi: "Mani Pedi",
      location: "Location",
      guests: "Guests",
      services: "Services",
      dateTime: "Date",
      edit: "Edit",
    },
  },
  popup: {
    mainMenu: {
      faq: "Faq",
      help: "Help",
      termsAndConditions: "Terms and conditions",
      privacyPolicy: "Privacy policy",
    },
    medicalOrAccessNeeds: {
      header: "Add medical info / access requirements",
      label: "Medical Info / Access Needs",
      description:
        "Please add any details of any allergies, medical conditions or contra-indications that we should be aware of, or any special access requirements.",
      save: "Save",
      error: "Please enter some details about any medical information or access requirements before saving.",
    },
    selectPaymentAccount: {
      addCard: "Add a new card",
      header: "Select a card to use",
      card: "Card ending in",
      next: "Next",
    },
    selectEmployee: {
      header: "Choose Your Nail Artist",
      any: "Any Nail Artist",
      next: "Select Nail Artist",
    },
  },
  serviceCard: {
    learnMore: "Learn More",
  },
  progress: {
    edit: "Edit",
    stages: {
      location: "Location",
      guests: "Guests",
      services: "Services",
      dateTime: "Time",
      confirm: "Confirm",
    },
  },
  navigationBar: {
    next: "Next",
    back: "Back",
    reserve: "Reserve",
    confirm: "Confirm Booking",
  },
  locations: {
    header: "Choose A Location To Book",
  },
  numberOfGuests: {
    header: "For How Many People?",
    one: "Just Me",
    many: "Two or More",
  },
  selectNumberOfGuests: {
    header: "Select number of guests",
    label1: "1",
    label2: "2",
    label3: "3",
    label4: "4",
    label5: "5",
    label6: "6",
    label7Plus: "7+ Guests",
    privateEventsPage: "https://townhouse.co.uk/private-events/",
  },
  groupPackages: {
    header: "Choose Your Group Package for all Guests",
    individualServices: "Or choose services individually",
  },
  guests: {
    header: "Your Guests",
  },
  guestsMultipleServices: {
    noServices: "No services selected",
    addServices: "Add services",
    edit: "Edit",
    copy: "Copy to all guests",
  },
  guestsGroupPackageServices: {
    chooseAddOns: "Choose Add-Ons",
    edit: "Edit Add-Ons",
    copy: "Copy to all guests",
    includesRemoval: "(Includes gel removal)",
  },
  services: {
    header: "Choose Your Service",
    maniPedi: {
      manicureHeader: "Mani-Pedi — Manicures",
      pedicureHeader: "Mani-Pedi — Pedicures",
    },
  },
  addOns: {
    headerManicure: "Choose Add-Ons For Your Manicure",
    headerPedicure: "Choose Add-Ons For Your Pedicure",
  },
  needsRemoval: {
    headerManicure: "Do You Need Removal For Your Manicure?",
    headerPedicure: "Do You Need Removal For Your Pedicure?",
  },
  removals: {
    headerManicure: "What Type Of Removal Do You Need?",
    headerPedicure: "What Type Of Removal Do You Need?",
  },
  dateTime: {
    nailArtist: {
      any: "With Any Nail Artist",
      chosen: "With {name}",
    },
    header: "Choose Your Appointment Time",
    allTimes: "All Times",
    showMoreTimes: "Show More Times",
    noTimesLeft: "There are no appointment times remaining on this day",
    timeFormat: "H:mm",
  },
  confirm: {
    header: "Confirm Your Appointment Details",
    expiresIn: "Your reservation will expire in",
    promo: {
      inputLabel: "Have a promo code?",
      apply: "apply",
    },
    total: {
      header: "Total",
      label: "Payable at location",
    },
    medicalInfo: "Add medical info / access needs",
    payment: {
      header: "Secure your booking with",
      label: "(Your card will not be charged until the day of appointment)",
      addCard: "Add a new card",
      card: "Card ending in",
      change: "Change",
    },
    terms:
      "Tick to confirm agreement with our Terms and Conditions. We require card details to hold your booking - you will not be charged. You are free to cancel/change your appointment up to 6 hours before with no charge. Cancellations/changes with less than 6 hours notice and no shows are charged in full using the card on file.",
    confirmBooking: "Confirm booking",
  },
  complete: {
    header: "Your Reservation Is Confirmed",
    subHeader: "Thanks for booking with Townhouse",
    total: {
      header: "Total",
      label: "Payable at location",
    },
    bookAgain: "Book Again",
  },
  createAccount: {
    header: "Create Account",
    submit: "Create Account",
    alreadyHaveAccount: "Already have an account?",
    login: "Log in",
    label: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phone: "Phone",
      dateOfBirth: "Date of Birth",
      password: "Password",
      marketingOptIns: "Yes please — sign me up to exclusive offers, birthday treats and collaboration freebies!",
      marketingEmailOptIn: "Email",
      marketingSmsOptIn: "SMS",
    },
    placeholder: {
      password: "Try letters, numbers + symbols",
    },
    error: {
      all: "Failed to create an account. Please check that the email or phone number you used has not already been used previously and try again.",
      firstNameInvalid: "Please enter your first name",
      lastNameInvalid: "Please enter your last name",
      emailInvalid: "Please enter a valid email address",
      phoneInvalid: "Please enter a valid mobile phone number",
      dateOfBirthInvalid: "Please enter a valid day, month and year of birth",
      dateOfBirthUnder16: "You must be 16 or older to create an account",
      passwordInvalid:
        "Please enter a password of 8 characters or more with at least one lowercase letter, uppercase letter, number and symbol",
    },
  },
  login: {
    header: "Log In",
    submit: "Log In",
    forgottenPassword: "Forgot your password?",
    createAccount: "Create a new account",
    errored: "The email or password you entered was not correct. Please try again.",
    emailInvalid: "Please enter a valid email address",
    passwordInvalid: "Please enter a valid password",
  },
  resetPassword: {
    header: "Reset Password",
    email: {
      label: "Email",
      description: "Please enter your email address and we will send you a verification code.",
      invalid: "Please enter a valid email address",
    },
    verify: {
      label: "Verification Code",
      description: "We have sent a verification code to {email}, if an account exists with that email.",
      invalid: "Please enter a verification code",
    },
    password: {
      label: "New Password",
      invalid:
        "Please enter a password of 8 characters or more with at least one lowercase letter, uppercase letter, number and symbol",
    },
    success: {
      description: "Your password has been changed.",
    },
    button: {
      verificationCode: "Send Verification Code",
      updatePassword: "Update Password",
      login: "Go to login",
    },
    error: {
      all: "Failed to update password. Please check that the details you have entered are valid.",
      invalidVerificationCode:
        "Failed to update password. Please check that the verification code you have entered is correct.",
      accountLocked:
        "Failed to update password. Your account has been locked after too many failed attempts. Please try again later.",
    },
  },
  addPaymentAccount: {
    header: "Add A New Card",
    unknownError: "An unknown error occured whilst trying to add your new payment account. Please try again.",
  },
  input: {
    optionalLabel: "(Optional)",
  },
  button: {
    back: "Back",
  },
  calendar: {
    dayName: {
      sunday: "Sun",
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
    },
  },
  general: {
    mainWebsite: "https://townhouse.co.uk",
    faq: "https://townhouse.co.uk/faq",
    help: "https://townhouse.co.uk/faq",
    termsAndConditions: "https://townhouse.co.uk/terms-and-conditions",
    privacyPolicy: "https://townhouse.co.uk/privacy-policy",
    guestLabel: "Guest {guestNumber}",
  },
};
